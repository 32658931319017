import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import LeistungAuswahl from "./LeistungAuswahl";
import { PERSON_GESCHLECHT } from "../../../lib/constants";
import { berechtigungFuer } from "../lib/berechtigungen";
import DatumPicker from "../../../components/DatumPicker";
import { executeRequest } from "../lib/fetchHelper";
import SimpleSelect from "./SimpleSelect";
import FilterButton from "../../../components/FilterButton";

export const DEFAULT_FILTER = {
  person: null, strasse: null, plz: null, ort: null,
  betragFreiwillig: null, leistung: null, betragVon: null, betragBis: null, geschlecht: null,
  doppelteAdressen: false, ohneAdresse: false, rolleImVerein: null, personenInVerein: null,
  bexio: [], fehlerInBexio: null,
  team: null, ohneTeam: null,
  nationalitaetSchweiz: null, wohnsitzSchweiz: null,
  spielerGesperrt: null, qualifikationKleinerAls: null, qualifikationGroesserAls: null
};

const RechnungenFilter = ({rechnungslaufTyp, rollenTypen, api, bexio, teams, i18n, aktiveBexioStati, showFilter, resetFilter, onFilterChange}) => {
  const [filterValues, setFilterValues] = useState(DEFAULT_FILTER);
  const [abfrageAktiv, setAbfrageAktiv] = useState(false);
  const texte = i18n.texte;
  const texteRechnungen = texte.rechnungen;
  useEffect(() => {
    if (resetFilter > 0) {
      setFilterValues({ ...DEFAULT_FILTER });
    }
  }, [resetFilter]);

  useEffect(() => {
    if (bexio.aktiv) {
      if (bexio.resourcenFehler.length > 0) {
        filterChange('fehlerInBexio', true);
      } else if (filterValues.fehlerInBexio) {
        filterChange('fehlerInBexio', null);
      }
    }
  }, [bexio.resourcenFehler]);

  const showBetragFreiwillig = berechtigungFuer(rechnungslaufTyp, 'betragFreiwillig');
  const showTeam = berechtigungFuer(rechnungslaufTyp, 'team');

  const filterChange = (feldname, wert, fireChangeEvent = true) => {
    const filter = { ...filterValues };
    if (wert === '') {
      filter[feldname] = null;
    } else {
      filter[feldname] = wert;
    }
    setFilterValues(filter);
    if (fireChangeEvent) {
      onFilterChange(filter);
    }
  };

  // doppelte adressen können hiermit auch gegen alle adressen einer rolle im verein geprüft werden
  let abortController = null;

  function abfragen(rolleTyp) {
    // komplizierter aufbau wegen xhr abfrage -> gibt sonst react fehler
    async function xhrCall(rolleTyp,) {
      abortController = new AbortController();
      executeRequest(api.personenInVerein, api.token, 'GET', { rolleTyp: rolleTyp }, { signal: abortController.signal })
        .then(result => {
          if (result.ok) {
            filterChange('personenInVerein', result.data.personen);
          }
        })
        .catch(() => {
          // noop, aber drin wegen AbortController.abort()
        }).finally(() => {
        filterChange('rolleImVerein', rolleTyp, false);
        setAbfrageAktiv(false);
      });
    }

    if (abortController) {
      abortController.abort();
    }

    filterChange('rolleImVerein', rolleTyp, false);

    if (!rolleTyp) {
      filterChange('personenInVerein', null);
    } else {
      setAbfrageAktiv(true);
      xhrCall(rolleTyp).then();
    }
  }

  if (!showFilter) {
    return <></>;
  }

  return (
    <table className="table attributes">
      <tbody>
      <tr>
        <th>{texteRechnungen.person}</th>
        <td>
          <input type="text" value={filterValues.person || ''} onChange={ev => filterChange('person', ev.currentTarget.value)}/>
        </td>
        <th>{showBetragFreiwillig && texteRechnungen.betragFreiwillig}</th>
        <td>
          {showBetragFreiwillig &&
            <span className="btn-group">
              <FilterButton value={filterValues.betragFreiwillig} zielwert={true} onClick={wert => filterChange('betragFreiwillig', wert)}>{texte.ja}</FilterButton>
              <FilterButton value={filterValues.betragFreiwillig} zielwert={false} onClick={wert => filterChange('betragFreiwillig', wert)}>{texte.nein}</FilterButton>
            </span>
          }
        </td>
      </tr>
      <tr>
        <th>{texteRechnungen.strasse}</th>
        <td>
          <input type="text" value={filterValues.strasse || ''} onChange={ev => filterChange('strasse', ev.currentTarget.value)}/>
        </td>
        <th>{texteRechnungen.plz} / {texteRechnungen.ort}</th>
        <td className="text-nowrap">
          <input type="text" value={filterValues.plz || ''} onChange={ev => filterChange('plz', ev.currentTarget.value)} style={{ width: 42 }}/>
          &nbsp;
          <input type="text" value={filterValues.ort || ''} onChange={ev => filterChange('ort', ev.currentTarget.value)} style={{ width: 158 }}/>
        </td>
      </tr>
      <tr>
        <th>{texteRechnungen.doppelteAdressen}</th>
        <td>
          <FilterButton value={filterValues.doppelteAdressen} zielwert={true} onClick={wert => filterChange('doppelteAdressen', wert)}>{texte.ja}</FilterButton>
        </td>
        <th>{texteRechnungen.ohneAdresse}</th>
        <td>
          <FilterButton value={filterValues.ohneAdresse} zielwert={true} onClick={wert => filterChange('ohneAdresse', wert)}>{texte.ja}</FilterButton>
        </td>
      </tr>
      <tr>
        <th>{texteRechnungen.doppelteAdressenVerein}</th>
        <td colSpan={3}>
          <select value={filterValues.rolleImVerein || ''} onChange={ev => abfragen(ev.currentTarget.value)}>
            <option value=""></option>
            {rollenTypen.filter(rt => !rt.typ.startsWith('verein')).map(rt => <option key={rt.typ} value={rt.typ}>{rt.bezeichnung}</option>)}
          </select>
          {abfrageAktiv && <span className="ajax-spinner-klein"/>}
        </td>
      </tr>
      <tr>
        <th>{texteRechnungen.leistung}</th>
        <td>
          <LeistungAuswahl onLeistungChange={l => filterChange('leistung', l ? l.id : '')} istEntfernbar={true}/>
        </td>
        <th>{texteRechnungen.betrag} {texte.von} / {texte.bis}</th>
        <td className="text-nowrap">
          <input className="betrag" type="number" min={0} step={1} value={filterValues.betragVon || ''} onChange={ev => filterChange('betragVon', ev.currentTarget.value)}/>
          &nbsp;
          <input className="betrag" type="number" min={0} step={1} value={filterValues.betragBis || ''} onChange={ev => filterChange('betragBis', ev.currentTarget.value)}/>
        </td>
      </tr>
      <tr>
        <th>{texteRechnungen.nationalitaetSchweiz}</th>
        <td>
          <span className="btn-group">
            <FilterButton value={filterValues.nationalitaetSchweiz} zielwert={true} onClick={wert => filterChange('nationalitaetSchweiz', wert)}>{texte.ja}</FilterButton>
            <FilterButton value={filterValues.nationalitaetSchweiz} zielwert={false} onClick={wert => filterChange('nationalitaetSchweiz', wert)}>{texte.nein}</FilterButton>
            </span>
        </td>
        <th>{texteRechnungen.wohnsitzSchweiz}</th>
        <td>
          <span className="btn-group">
            <FilterButton value={filterValues.wohnsitzSchweiz} zielwert={true} onClick={wert => filterChange('wohnsitzSchweiz', wert)}>{texte.ja}</FilterButton>
            <FilterButton value={filterValues.wohnsitzSchweiz} zielwert={false} onClick={wert => filterChange('wohnsitzSchweiz', wert)}>{texte.nein}</FilterButton>
          </span>
        </td>
      </tr>
      <tr>
        <th>{texteRechnungen.qualifikationKleinerAls}</th>
        <td>
          <DatumPicker value={filterValues.qualifikationKleinerAls || ''} onChange={d => filterChange('qualifikationKleinerAls', d)} locale={i18n.locale}/>
        </td>
        <th>{texteRechnungen.qualifikationGroesserAls}</th>
        <td>
          <DatumPicker value={filterValues.qualifikationGroesserAls || ''} onChange={d => filterChange('qualifikationGroesserAls', d)} locale={i18n.locale}/>
        </td>
      </tr>
      {showTeam &&
        <>
          <tr>
            <th>{texteRechnungen.team}</th>
            <td>
              <select value={filterValues.team || ''} onChange={ev => filterChange('team', ev.currentTarget.value)}>
                <option value=""></option>
                {teams.map(t => <option key={t.id} value={t.id}>{t.bezeichnung}</option>)}
              </select>
            </td>
            <th>{texteRechnungen.ohneTeam}</th>
            <td>
              <span className="btn-group">
                <FilterButton value={filterValues.ohneTeam} zielwert={true} onClick={wert => filterChange('ohneTeam', wert)}>{texte.ja}</FilterButton>
                <FilterButton value={filterValues.ohneTeam} zielwert={false} onClick={wert => filterChange('ohneTeam', wert)}>{texte.nein}</FilterButton>
              </span>
            </td>
          </tr>
          <tr>
            <th>{texteRechnungen.geschlecht}</th>
            <td>
            <span className="btn-group">
              <FilterButton value={filterValues.geschlecht} zielwert={PERSON_GESCHLECHT.mann} onClick={wert => filterChange('geschlecht', wert)}>{texteRechnungen.mann}</FilterButton>
              <FilterButton value={filterValues.geschlecht} zielwert={PERSON_GESCHLECHT.frau} onClick={wert => filterChange('geschlecht', wert)}>{texteRechnungen.frau}</FilterButton>
            </span>
            </td>
            <th>{texteRechnungen.vereinsinternGesperrt}</th>
            <td>
            <span className="btn-group">
              <FilterButton value={filterValues.spielerGesperrt} zielwert={true} onClick={wert => filterChange('spielerGesperrt', wert)}>{texte.ja}</FilterButton>
              <FilterButton value={filterValues.spielerGesperrt} zielwert={false} onClick={wert => filterChange('spielerGesperrt', wert)}>{texte.nein}</FilterButton>
            </span>
            </td>
          </tr>
        </>
      }
      {bexio.aktiv &&
        <tr>
          <th>{texteRechnungen.statusInBexio}</th>
          <td>
            <SimpleSelect isMulti value={filterValues.bexio} options={aktiveBexioStati.map(s => ({ label: s.text, value: s.key }))} onChange={val => filterChange('bexio', val)}/>
          </td>
          {bexio.resourcenFehler && bexio.resourcenFehler.length > 0 ? (
            <>
              <th>{texteRechnungen.fehlerInBexio}</th>
              <td>
                <FilterButton value={filterValues.fehlerInBexio} zielwert={true} onClick={wert => filterChange('fehlerInBexio', wert)}>{texte.ja}</FilterButton>
              </td>
            </>
          ) : (
            <>
              <th></th>
              <td></td>
            </>
          )}
        </tr>
      }
      </tbody>
    </table>
  );
};

const mapStateToProps = (state) => {
  return {
    rechnungslaufTyp: state.rechnungslauf.typ,
    rollenTypen: state.typen.rollen,
    api: state.api,
    bexio: state.bexio,
    teams: state.teams,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RechnungenFilter);

