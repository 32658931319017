import React, {useMemo, useState} from "react";
import {connect} from "react-redux";
import {executeGet} from "../../../../lib/fetchHelper";
import {formatiereBetrag} from "../../../../lib/formatierungen";
import {spielerDelete} from "../../state/modules/sponsorenlaufSpieler";
import {listenFehler, listenTeams} from "../../state/modules/sponsorenlaufListen";
import PersonBadge from "../../../../components/PersonBadge";
import TeamBadge from "../../../../components/TeamBadge";
import ConfirmButton from "../../../../components/ConfirmButton";
import Pager from "../../../../components/Pager";
import SpielerHinzufuegenModal from "./SpielerHinzufuegenModal";
import SpielerBearbeiten from "./SpielerBearbeiten";
import Filter from "./Filter";
import {filtereSponsorenlauf} from "../../lib/datenHelper";
import Tooltip from "../../../../components/Tooltip";
import SpielerFisNachrichtModal from "./SpielerFisNachrichtModal";
import EmailSendenModal from "./EmailSendenModal";

const TYP = {
  NONE: '', 
  TEAM: 'teamId', 
  SPIELERKATEGORIE: 'spielerkategorieId'
};

const Spieler = ({sponsorenlauf, spielerliste, teams, sponsoren, fehler, spielerkategorien, pageSizeArray, i18n, api, spielerDelete, listenTeams, listenFehler}) => {
  const [kategorieId, setKategorieId] = useState(spielerkategorien[0].id);
  const [teamId, setTeamId] = useState(teams[0].id);
  const [personenliste, setPersonenliste] = useState(undefined);
  const [abfrageLaeuft, setAbfrageLaeuft] = useState('');
  const [spielerSelektiert, setSpielerSelektiert] = useState([]); // hält sponsorenlauf-spieler-ids
  const [alleSelektiert, setAlleSelektiert] = useState(false);
  const [selectedTyp, setSelectedTyp] = useState(TYP.NONE);
  const [showEdit, setShowEdit] = useState(false);
  const [showFisNachricht, setShowFisNachricht] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const darfSpielerLoeschen = useMemo(() => sponsorenlauf.registrationBis >= new Date(), [sponsorenlauf.registrationBis]);
  const texte = i18n.texte;
  const texteSpieler = texte.spieler;

  const [showFilter, setShowFilter] = useState(false);
  const [filterValues, setFilterValues] = useState(null);
  const [pageSize, setPageSize] = useState(999999);
  const [pageIndex, setPageIndex] = useState(1);

  const personenLaden = (key, value) => {
    setAbfrageLaeuft(key);
    setSelectedTyp(key)
    executeGet(api.spieler, {sponsorenlaufId: sponsorenlauf.id, [key]: value})
      .then(result => {
        if (result.data.fremdeTeams.length > 0) {
          listenTeams(result.data.fremdeTeams);
        }
        setPersonenliste(result.data.personen);
        setAbfrageLaeuft(TYP.NONE);
      });
  };

  const spielerSelektieren = (sponsorenlaufSpielerId, checked) => {
    const neueSpielerIds = spielerSelektiert.filter(id => id !== sponsorenlaufSpielerId);
    if (checked) {
      neueSpielerIds.push(sponsorenlaufSpielerId);
    }
    setSpielerSelektiert(neueSpielerIds);
  };

  const alleSelektierenChange = (selektiert) => {
    setSpielerSelektiert(selektiert ? paginated.map(s => s.id) : []);
    setAlleSelektiert(selektiert);
  };

  const loeschenClick = () => {
    spielerDelete(spielerSelektiert)
    setSpielerSelektiert([]);
  };

  const alleTeamIds = useMemo(() => {
    return spielerliste.map(s => s.teamId).filter((id, index, self) => self.indexOf(id) === index);
  }, [spielerliste]);

  const filterReset = () => {
    setFilterValues(null);
    pageIndexChange();
  };

  const pageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    pageIndexChange();
  };

  const pageIndexChange = (index = 1) => {
    setSpielerSelektiert([]);
    setPageIndex(index);
    setAlleSelektiert(false);
  };

  const gefilterteSpielerliste = useMemo(() => {
    pageIndexChange();
    return filtereSponsorenlauf(spielerliste, filterValues, spielerkategorien, sponsoren);
  }, [spielerliste, filterValues])

  const paginated = useMemo(() => {
    let startIndex = (pageIndex - 1) * pageSize;
    let endIndex = Math.min(pageIndex * pageSize, spielerliste.length);
    return gefilterteSpielerliste.slice(startIndex, endIndex);
  }, [pageIndex, pageSize, gefilterteSpielerliste]);

  return (
    <section>
      <h2 className="page-section">{texteSpieler.titel} ({gefilterteSpielerliste.length})</h2>
      {fehler && 
      <div className="alert alert-error">
        <a className="close" onClick={() => listenFehler(null)}>×</a>
        <span>{fehler}</span>
      </div>
      }
      <div className="form-inline">
        <select className="form-control" onChange={ev => setKategorieId(ev.currentTarget.value)}>
          {spielerkategorien.map(kat => <option key={kat.id} value={kat.id}>{kat.bezeichnung}</option>)}
        </select>
        &nbsp;
        <button type="button" className="btn" onClick={() => personenLaden(TYP.SPIELERKATEGORIE, kategorieId)}>
          {texteSpieler.hinzufuegen}
          {abfrageLaeuft == TYP.SPIELERKATEGORIE && <div className="ajax-spinner-klein"/>}
        </button>
        <div className="pull-right">
          {filterValues === null ||
            <>
              <Tooltip tagName="button" className="btn btn-small" content={texte.filter.loeschen} onClick={filterReset}>
                <i className="icon-remove"/>
              </Tooltip>
              &nbsp;
            </>
          }
          <button onClick={() => setShowFilter(!showFilter)} className="btn btn-small"><i className="icon-filter"/>{texte.filter.button}</button>
        </div>
      </div>
      <div className="form-inline" style={{margin: '10px 0'}}>
        <select className="form-control" onChange={ev => setTeamId(ev.currentTarget.value)}>
          {teams.map(team => <option key={team.id} value={team.id}>{team.bezeichnung}</option>)}
        </select>
        &nbsp;
        <button type="button" className="btn" onClick={() => personenLaden(TYP.TEAM, teamId)}>
          {texteSpieler.hinzufuegenTeam}
          {abfrageLaeuft == TYP.TEAM && <div className="ajax-spinner-klein"/>}
        </button>
      </div>
      {showFilter &&
      <Filter filterValues={filterValues} teamIds={alleTeamIds} onFilterChange={filter => setFilterValues(filter)}/>
      }
      <div className="form-actions text-right">
        <button type="button" className="btn" disabled={spielerSelektiert.length == 0} onClick={() => setShowEmail(true)}>{texteSpieler.selektierteEmail}</button>
        &nbsp;
        <button type="button" className="btn" disabled={spielerSelektiert.length == 0} onClick={() => setShowFisNachricht(true)}>{texteSpieler.selektierteFisNachricht}</button>
        &nbsp;
        <button type="button" className="btn" disabled={spielerSelektiert.length == 0} onClick={() => setShowEdit(true)}>{texteSpieler.selektierteBearbeiten}</button>
        {darfSpielerLoeschen &&
        <>
          &nbsp;
          <ConfirmButton className="btn btn-danger" disabled={spielerSelektiert.length == 0} onConfirmed={loeschenClick} confirmText={texte.wirklichLoeschen} okText={texte.ok} cancelText={texte.abbrechen}>{texteSpieler.selektierteLoeschen}</ConfirmButton>
        </>
        }
      </div>
      <Pager pageIndex={pageIndex} pageSize={pageSize} totalItemCount={gefilterteSpielerliste.length} pageSizeArray={pageSizeArray} onPageChange={pageIndexChange} onPageSizeChange={pageSizeChange}/>
      <table className="table attributes table-striped">
        <thead>
        <tr>
          <th>{texteSpieler.spieler}</th>
          <th>{texteSpieler.team}</th>
          <th></th>
          <th>{texteSpieler.pflichtbeitrag}</th>
          <th><input type="checkbox" checked={alleSelektiert} onChange={ev => alleSelektierenChange(ev.currentTarget.checked)}/></th>
        </tr>
        </thead>
        <tbody>
        {paginated.map(spieler => {
          let team = teams.find(t => t.id === spieler.teamId);
          return <tr key={spieler.id}>
            <td>
              <PersonBadge person={spieler.person}/>
              { spieler.externeVereine &&
                <div className="text-muted">
                  {spieler.externeVereine.map(verein => <div key={verein}>{verein}</div>)}
                </div>
              }
            </td>
            <td>{team && <TeamBadge team={team}/>}</td>
            <td>
              {spieler.gesperrt && <span className="label label-important label-umbruch">{texte.filter.vereinsinternGesperrt}</span>}
              {spieler.abmeldenMarkiert && <span className="label label-important label-umbruch">{texte.filter.zumAbmeldenMarkiert}</span>}
            </td>
            <td>{formatiereBetrag(spieler.pflichtbeitrag, i18n.locale)}</td>
            <td><input type="checkbox" checked={spielerSelektiert.includes(spieler.id)} onChange={ev => spielerSelektieren(spieler.id, ev.currentTarget.checked)}/></td>
          </tr>;
        })}
        </tbody>
      </table>
      <Pager pageIndex={pageIndex} pageSize={pageSize} totalItemCount={gefilterteSpielerliste.length} pageSizeArray={pageSizeArray} onPageChange={pageIndexChange} onPageSizeChange={pageSizeChange}/>
      {personenliste &&
      <SpielerHinzufuegenModal typ={selectedTyp} teamId={teamId} spielerkategorieId={kategorieId} personen={personenliste} onModalClose={() => setPersonenliste(null)} />
      }
      {showEdit &&
      <SpielerBearbeiten spielerliste={spielerSelektiert} onModalClose={() => setShowEdit(false)}/>
      }
      {showFisNachricht &&
      <SpielerFisNachrichtModal spielerliste={spielerSelektiert} onModalClose={() => setShowFisNachricht(false)} />
      }
      {showEmail &&
      <EmailSendenModal spielerliste={spielerSelektiert} onModalClose={() => setShowEmail(false)} />
      }
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    sponsorenlauf: state.sponsorenlauf,
    spielerliste: state.spieler,
    teams: state.listen.teams,
    sponsoren: state.sponsoren,
    fehler: state.listen.fehler,
    spielerkategorien: state.listen.spielerkategorien,
    pageSizeArray: state.listen.pager,
    i18n: state.i18n,
    api: state.api,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    spielerDelete: (spielerId) => { dispatch(spielerDelete(spielerId)) },
    listenTeams: (teams) => { dispatch(listenTeams(teams)) },
    listenFehler: (fehler) => { dispatch(listenFehler(fehler)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Spieler);
