const LAUFENDE_ABFRAGEN_SETZEN = 'LAUFENDE_ABFRAGEN_SETZEN';

export const requestTyp = {
  rechnungslauf: 'rechnungslauf',
  bexioCheckPersonen: 'bexioCheckPersonen',
  bexioCheckKonten: 'bexioCheckKonten',
  bexioCheckRechnungen: 'bexioCheckRechnungen',
  bexioCheckRechnungenStatus: 'bexioCheckRechnungenStatus',
  bexioTransfer: 'bexioTransfer',
  sponsorenlaufAlleBerechnen: 'sponsorenlaufAlleBerechnen',
}; 

export function startRequest(abfrageTyp) {
  return (dispatch) => {
    dispatch({type: LAUFENDE_ABFRAGEN_SETZEN, abfrageTyp: abfrageTyp, abfrageAktiv: true});
  };
}

export function endeRequest(abfrageTyp) {
  return (dispatch) => {
    dispatch({type: LAUFENDE_ABFRAGEN_SETZEN, abfrageTyp: abfrageTyp, abfrageAktiv: false});
  };
}

function laufendeRequestsReducer(state = {}, action) {
  switch (action.type) {
    case LAUFENDE_ABFRAGEN_SETZEN:
      const temp = {...state};
      temp[action.abfrageTyp] = action.abfrageAktiv;
      return temp;
    default:
      return state;
  }
}

export default laufendeRequestsReducer;
