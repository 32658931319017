import {executeRequest} from "../../lib/fetchHelper";
import {bexioAlleStatusSetzen, bexioRechnungenTotalSetzen} from "./bexio";

const RECHNUNGEN_SETZEN = 'RECHNUNGEN_SETZEN';
const RECHNUNGEN_UPDATE = 'RECHNUNGEN_UPDATE';
const RECHNUNGEN_UPDATE_LISTE = 'RECHNUNGEN_UPDATE_LISTE';
const RECHNUNGEN_DELETE = 'RECHNUNGEN_DELETE';

export function rechnungSortieren(a, b) {
  return a.person.name.localeCompare(b.person.name);
}

export function rechnungenSetzen(rechnungen) {
  return (dispatch) => {
    dispatch({type: RECHNUNGEN_SETZEN, rechnungen: rechnungen});
  };
}

export function rechnungHinzufuegen(rechnung) {
  return (dispatch) => {
    dispatch({type: RECHNUNGEN_UPDATE, rechnung: rechnung});
  };
}

export function rechnungShow(rechnung) {
  // rechnung neu laden
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;
    const daten = {
      rechnungslaufId: state.rechnungslauf.id,
      rechnungId: rechnung.id,
    };
    return executeRequest(api.rechnung, api.token, 'GET', daten)
      .then(result => {
        if (result.ok) {
          dispatch({type: RECHNUNGEN_UPDATE, rechnung: result.data.rechnung});
          if (state.bexio.aktiv) {
            dispatch(bexioAlleStatusSetzen(result.data));
          }
        } else {
          if (result.status === 404) {
            // rechnung ist bereits gelöscht
            dispatch({type: RECHNUNGEN_DELETE, rechnung: rechnung});
          }
        }
      });
  };
}

export function rechnungCreate(personId, personenIds, leistungId, betragFreiwillig, betrag, bemerkungen) {
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;
    const daten = {
      rechnungslaufId: state.rechnungslauf.id,
      leistungId: leistungId,
      betragFreiwillig: betragFreiwillig,
    };
    if (personId !== null) {
      daten['personId'] = personId;
    }
    if (personenIds !== null) {
      daten['personenIds'] = personenIds;
    }
    if (bemerkungen !== null) {
      daten['bemerkungen'] = bemerkungen;
    }
    if (betrag) {
      daten['betrag'] = betrag;
    }

    return executeRequest(api.rechnung, api.token, 'POST', daten)
      .then(result => {
        if (result.ok) {
          if (result.data.rechnung) {
            dispatch({type: RECHNUNGEN_UPDATE, rechnung: result.data.rechnung});
          } else if (result.data.rechnungen) {
            dispatch({type: RECHNUNGEN_UPDATE_LISTE, rechnungen: result.data.rechnungen});
          }
          if (state.bexio.aktiv) {
            dispatch(bexioRechnungenTotalSetzen());
            dispatch(bexioAlleStatusSetzen(result.data));
          }
        }
      });
  };
}

export function rechnungUpdate(rechnung, personId, leistungId, betragFreiwillig, betrag, bemerkungen) {
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;
    const daten = {
      rechnungslaufId: state.rechnungslauf.id,
      rechnungId: rechnung.id,
    };
    // nur die felder abfüllen die auch werte gesetzt haben
    if (personId) {
      daten['personId'] = personId;
    }
    if (leistungId) {
      daten['leistungId'] = leistungId;
    }
    if (betragFreiwillig !== null) {
      daten['betragFreiwillig'] = betragFreiwillig;
    }
    if (betrag) {
      daten['betrag'] = betrag;
    }
    if (bemerkungen !== null) {
      daten['bemerkungen'] = bemerkungen;
    }
    return executeRequest(api.rechnung, api.token, 'PUT', daten)
      .then(result => {
        if (result.ok) {
          if (rechnung.bexioReferenzId !== result.data.rechnung.bexioReferenzId) {
            // bexio referenz hat geändert -> meist weil rechnung in bexio gelöscht.
            // falls gruppierte rechnung vorhanden, diese auch neu laden
            getState().rechnungen
              .filter(r => r.id != rechnung.id && r.bexioReferenzId === rechnung.bexioReferenzId)
              .forEach(r => dispatch(rechnungShow(r)));
          }
          dispatch({type: RECHNUNGEN_UPDATE, rechnung: result.data.rechnung});
          if (state.bexio.aktiv) {
            dispatch(bexioAlleStatusSetzen(result.data));
          }
        } else {
          if (result.status === 404) {
            // rechnung ist bereits gelöscht
            dispatch({type: RECHNUNGEN_DELETE, rechnung: rechnung});
          }
        }
      });
  };
}

export function rechnungenBemerkungenUpdate(rechnungen, bemerkungen) {
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;
    const daten = {
      rechnungslaufId: state.rechnungslauf.id,
      typ: 'bemerkungen',
      rechnungId: rechnungen.map(r => r.id),
      bemerkungen: bemerkungen
    };
    return executeRequest(api.rechnung, api.token, 'PUT', daten)
      .then(result => {
        if (result.ok) {
          dispatch({type: RECHNUNGEN_UPDATE_LISTE, rechnungen: result.data.rechnungen});
        }
      });
  };
}

export function rechnungenStornieren(rechnungen) {
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;
    const daten = {
      rechnungslaufId: state.rechnungslauf.id,
      typ: 'stornieren',
      rechnungId: rechnungen.map(r => r.id)
    };
    return executeRequest(api.rechnung, api.token, 'PUT', daten)
      .then(result => {
        if (result.ok) {
          dispatch({type: RECHNUNGEN_UPDATE_LISTE, rechnungen: result.data.rechnungen});
        }
      });
  };
}

export function rechnungDelete(rechnung) {
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;
    const daten = {
      rechnungslaufId: state.rechnungslauf.id,
      rechnungId: rechnung.id,
    };
    return executeRequest(api.rechnung, api.token, 'DELETE', daten)
      .then(result => {
        if (result.ok) {
          dispatch({type: RECHNUNGEN_DELETE, rechnung: result.data.rechnung});
          if (state.bexio.aktiv) {
            dispatch(bexioRechnungenTotalSetzen());
            dispatch(bexioAlleStatusSetzen(result.data));
          }
        } else {
          if (result.status === 404) {
            // rechnung ist bereits gelöscht
            dispatch({type: RECHNUNGEN_DELETE, rechnung: rechnung});
          }
        }
      });
  };
}

export function rechnungSpielerSperren(rechnungen, sperren, bemerkungen = null) {
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;
    const daten = {
      rechnungslaufId: state.rechnungslauf.id,
      rechnungId: rechnungen.map(r => r.id),
      sperren : sperren,
      bemerkungen: bemerkungen
    };
    return executeRequest(api.spieler, api.token, 'POST', daten)
      .then(result => {
        dispatch({type: RECHNUNGEN_UPDATE_LISTE, rechnungen: result.data.rechnungen});
      });
  };
}

function rechnungenReducer(state = [], action) {
  switch (action.type) {
    case RECHNUNGEN_SETZEN:
      return action.rechnungen.sort(rechnungSortieren);
    case RECHNUNGEN_UPDATE:
      return [
        ...state.filter(r => r.id !== action.rechnung.id),
        action.rechnung
      ].sort(rechnungSortieren);
    case RECHNUNGEN_UPDATE_LISTE:
      const filterIds = action.rechnungen.map(r => r.id);
      return [
        ...state.filter(r => !filterIds.includes(r.id)),
        ...action.rechnungen
      ].sort(rechnungSortieren);
    case RECHNUNGEN_DELETE:
      return state
        .filter(r => r.id !== action.rechnung.id)
        .sort(rechnungSortieren);
    default:
      return state;
  }
}

export default rechnungenReducer;
