import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import LeistungAuswahl from "../LeistungAuswahl";
import {BEXIO_STATUS} from "../../lib/constants";
import {PERSON_GESCHLECHT} from "../../../../lib/constants";
import DatumPicker from "../../../../components/DatumPicker";
import SimpleSelect from "../SimpleSelect";
import FilterButton from "../../../../components/FilterButton";

export const DEFAULT_FILTER = {
  person: null, strasse: null, plz: null, ort: null,
  betragFreiwillig: false, leistung: null, betragVon: null, betragBis: null, geschlecht: null,
  bexio: [],
  mahnstufe: null, rechnungsdatumVon: null, rechnungsdatumBis: null, mahndatumVon: null, mahndatumBis: null,
  team: null, ohneTeam: null, spielerGesperrt: null,
  nationalitaetSchweiz: null, wohnsitzSchweiz: null
};

const RechnungenFilter = ({bexio, teams, i18n, onFilterChange}) => {
  const [filterValues, setFilterValues] = useState(DEFAULT_FILTER);
  const [bexioStati, setBexioStati] = useState([]);
  const texte = i18n.texte;
  const texteRechnungen = i18n.texte.rechnungen;

  const showBetragFreiwillig = true;
  const showTeam = teams.length > 0;

  // initial filter bekannt machen
  useEffect(() => {
    let filter = {...filterValues};
    if (bexio.aktiv) {
      // nur die vorhandenen bexio status als default setzen.
      filter.bexio = [BEXIO_STATUS.pending, BEXIO_STATUS.partial];
      const stati = [];
      for (let bs in BEXIO_STATUS) {
        stati.push({label: texte.bexio.status[bs], value: BEXIO_STATUS[bs]});
      }
      setBexioStati(stati);
      setFilterValues(filter);
    }
    onFilterChange(filter);
  }, []);

  const filterChange = (feldname, wert) => {
    const filter = {...filterValues};
    if (wert === '') {
      filter[feldname] = null;
    } else {
      filter[feldname] = wert;
    }
    setFilterValues(filter);
    onFilterChange(filter);
  };

  return (
    <table className="table attributes">
      <tbody>
      <tr>
        <th>{texteRechnungen.person}</th>
        <td>
          <input type="text" onChange={ev => filterChange('person', ev.currentTarget.value)}/>
        </td>
        <th>{showBetragFreiwillig && texteRechnungen.betragFreiwillig}</th>
        <td>
          {showBetragFreiwillig &&
            <span className="btn-group">
              <FilterButton value={filterValues.betragFreiwillig} zielwert={true} onClick={wert => filterChange('betragFreiwillig', wert)}>{texte.ja}</FilterButton>
              <FilterButton value={filterValues.betragFreiwillig} zielwert={false} onClick={wert => filterChange('betragFreiwillig', wert)}>{texte.nein}</FilterButton>
            </span>
          }
        </td>
      </tr>
      <tr>
        <th>{texteRechnungen.strasse}</th>
        <td>
          <input type="text" onChange={ev => filterChange('strasse', ev.currentTarget.value)}/>
        </td>
        <th>{texteRechnungen.plz} / {texteRechnungen.ort}</th>
        <td className="text-nowrap">
          <input type="text" onChange={ev => filterChange('plz', ev.currentTarget.value)} style={{width: 42}}/>
          &nbsp;
          <input type="text" onChange={ev => filterChange('ort', ev.currentTarget.value)} style={{width: 158}}/>
        </td>
      </tr>
      <tr>
        <th>{texteRechnungen.doppelteAdressen}</th>
        <td>
          <FilterButton value={filterValues.doppelteAdressen} zielwert={true} onClick={wert => filterChange('doppelteAdressen', wert)}>{texte.ja}</FilterButton>
        </td>
        <th></th>
        <td></td>
      </tr>
      <tr>
        <th>{texteRechnungen.leistung}</th>
        <td>
          <LeistungAuswahl onLeistungChange={l => filterChange('leistung', l ? l.id : '')} istEntfernbar={true}/>
        </td>
        <th>{texteRechnungen.betrag} {texteRechnungen.von} / {texteRechnungen.bis}</th>
        <td className="text-nowrap">
          <input className="betrag" type="number" min={0} step={1} onChange={ev => filterChange('betragVon', ev.currentTarget.value)}/>
          &nbsp;
          <input className="betrag" type="number" min={0} step={1} onChange={ev => filterChange('betragBis', ev.currentTarget.value)}/>
        </td>
      </tr>
      <tr>
        <th>{texteRechnungen.nationalitaetSchweiz}</th>
        <td>
          <span className="btn-group">
            <FilterButton value={filterValues.nationalitaetSchweiz} zielwert={true} onClick={wert => filterChange('nationalitaetSchweiz', wert)}>{texte.ja}</FilterButton>
            <FilterButton value={filterValues.nationalitaetSchweiz} zielwert={false} onClick={wert => filterChange('nationalitaetSchweiz', wert)}>{texte.nein}</FilterButton>
          </span>
        </td>
        <th>{texteRechnungen.wohnsitzSchweiz}</th>
        <td>
          <span className="btn-group">
            <FilterButton value={filterValues.wohnsitzSchweiz} zielwert={true} onClick={wert => filterChange('wohnsitzSchweiz', wert)}>{texte.ja}</FilterButton>
            <FilterButton value={filterValues.wohnsitzSchweiz} zielwert={false} onClick={wert => filterChange('wohnsitzSchweiz', wert)}>{texte.nein}</FilterButton>
          </span>
        </td>
      </tr>
      {showTeam &&
        <>
          <tr>
            <th>{texteRechnungen.team}</th>
            <td>
              <select onChange={ev => filterChange('team', ev.currentTarget.value)}>
                <option value=""></option>
                {teams.map(t => <option key={t.id} value={t.id}>{t.bezeichnung}</option>)}
              </select>
            </td>
            <th>{texteRechnungen.ohneTeam}</th>
            <td>
              <span className="btn-group">
                <FilterButton value={filterValues.ohneTeam} zielwert={true} onClick={wert => filterChange('ohneTeam', wert)}>{texte.ja}</FilterButton>
                <FilterButton value={filterValues.ohneTeam} zielwert={false} onClick={wert => filterChange('ohneTeam', wert)}>{texte.nein}</FilterButton>
              </span>
            </td>
          </tr>
          <tr>
            <th>{texteRechnungen.geschlecht}</th>
            <td>
            <span className="btn-group">
              <FilterButton value={filterValues.geschlecht} zielwert={PERSON_GESCHLECHT.mann} onClick={wert => filterChange('geschlecht', wert)}>{texteRechnungen.mann}</FilterButton>
              <FilterButton value={filterValues.geschlecht} zielwert={PERSON_GESCHLECHT.frau} onClick={wert => filterChange('geschlecht', wert)}>{texteRechnungen.frau}</FilterButton>
            </span>
            </td>
            <th>{texteRechnungen.vereinsinternGesperrt}</th>
            <td>
            <span className="btn-group">
              <FilterButton value={filterValues.spielerGesperrt} zielwert={true} onClick={wert => filterChange('spielerGesperrt', wert)}>{texte.ja}</FilterButton>
              <FilterButton value={filterValues.spielerGesperrt} zielwert={false} onClick={wert => filterChange('spielerGesperrt', wert)}>{texte.nein}</FilterButton>
            </span>
            </td>
          </tr>
        </>
      }
      {bexio.aktiv &&
        <>
          <tr>
            <th>{texteRechnungen.statusInBexio}</th>
            <td>
              <SimpleSelect isMulti value={filterValues.bexio} options={bexioStati} onChange={val => filterChange('bexio', val)} />
            </td>
            <th>{texteRechnungen.mahnstufe}</th>
            <td>
              <select onChange={ev => filterChange('mahnstufe', ev.currentTarget.value)}>
                <option value=""></option>
                {[1, 2, 3].map(z => <option key={z} value={z}>{`${texteRechnungen.mahnstufe} ${z}`}</option>)}
              </select>
            </td>
          </tr>
          <tr>
            <th>{texteRechnungen.rechnungsdatum}<br/>{texteRechnungen.von_bis}</th>
            <td>
              <DatumPicker locale={i18n.locale} value={filterValues.rechnungsdatumVon || ''} onChange={date => filterChange('rechnungsdatumVon', date)}/>
              &nbsp;
              <DatumPicker locale={i18n.locale} value={filterValues.rechnungsdatumBis || ''} onChange={date => filterChange('rechnungsdatumBis', date)}/>
            </td>
            <th>{texteRechnungen.mahndatum}<br/>{texteRechnungen.von_bis}</th>
            <td>
              <DatumPicker locale={i18n.locale} value={filterValues.mahndatumVon || ''} onChange={date => filterChange('mahndatumVon', date)}/>
              &nbsp;
              <DatumPicker locale={i18n.locale} value={filterValues.mahndatumBis || ''} onChange={date => filterChange('mahndatumBis', date)}/>
            </td>
          </tr>
        </>
      }
      </tbody>
    </table>
  );
};

const mapStateToProps = (state) => {
  return {
    bexio: state.bexio,
    teams: state.teams,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RechnungenFilter);

