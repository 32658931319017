import React from "react";

const FilterButton = ({children, value, zielwert, onClick}) => {
  // button, der zum toggeln von filter-werten benutzt werden kann.
  // onClick: wenn "value === zielwert" wird null zurückgeben, sonst der zielwert.
  return (
    <button type="button" className={`btn btn-small${value === zielwert ? ' btn-success' : ''}`} onClick={() => onClick(value === zielwert ? null : zielwert)}>{children}</button>
  );
};

export default FilterButton;

