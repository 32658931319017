import React, {useMemo, useState} from "react";
import {connect} from "react-redux";
import PersonBadge from "../../../../components/PersonBadge";
import TeamBadge from "../../../../components/TeamBadge";
import {formatiereBetrag, formatiereDatum} from "../../../../lib/formatierungen";
import {downloadCsv, filtereSponsorenlauf} from "../../lib/datenHelper";
import {spielerUpdate} from "../../state/modules/sponsorenlaufSpieler";
import Filter from "./Filter";
import Pager from "../../../../components/Pager";
import Tooltip from "../../../../components/Tooltip";
import IkonFuerDatei from "../../../../components/IkonFuerDatei";
import {requestTyp} from "../../state/modules/laufendeAbfragen";

const Gesamtuebersicht = ({spielerliste, teams, sponsoren, spielerkategorien, pageSizeArray, laufendeRequests, i18n, spielerUpdate}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [filterValues, setFilterValues] = useState(null);

  const texte = i18n.texte;
  const texteGesamtuebersicht = texte.gesamtuebersicht;

  const [pageSize, setPageSize] = useState(999999);
  const [pageIndex, setPageIndex] = useState(1);

  const alleTeamIds = useMemo(() => {
    return spielerliste.map(s => s.teamId).filter((id, index, self) => self.indexOf(id) === index);
  }, [spielerliste]);

  const filterReset = () => {
    setFilterValues(null);
    pageIndexChange();
  };

  const pageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    pageIndexChange();
  };

  const pageIndexChange = (index = 1) => {
    setPageIndex(index);
  };

  const gefilterteSpielerliste = useMemo(() => {
    let liste = filtereSponsorenlauf([...spielerliste], filterValues, spielerkategorien, sponsoren);
    liste.sort((a, b) => b.betragTotal - a.betragTotal);
    return liste;
  }, [spielerliste, filterValues])

  const paginated = useMemo(() => {
    let startIndex = (pageIndex - 1) * pageSize;
    let endIndex = Math.min(pageIndex * pageSize, spielerliste.length);
    return gefilterteSpielerliste.slice(startIndex, endIndex);
  }, [pageIndex, pageSize, gefilterteSpielerliste]);

  const generateCsv = (event) => {
    event.preventDefault();

    let data = [];
    data.push([
      texte.csv.nachname, texte.csv.vorname, texte.csv.geburtsdatum, 
      texte.spielerkategorie, texte.spieler.team, 
      texte.spieler.pflichtbeitrag, texte.anzahlRunden, 
      texte.betragPauschal, texte.betragRunde, texte.betragTotal, texte.betragRechnung, texte.gesamtuebersicht.ueberschuss
    ]);

    gefilterteSpielerliste.forEach(spieler => {
      let kat = spielerkategorien.find(kat => kat.von <= spieler.person.geburtsdatum && kat.bis >= spieler.person.geburtsdatum);
      let team = teams.find(t => t.id === spieler.teamId);
      let ergebnis = spieler.pflichtbeitrag - spieler.betragTotal;
      let rechnung = ergebnis > 0 ? ergebnis : 0;
      let ueberschuss = ergebnis < 0 ? Math.abs(ergebnis) : 0;
      data.push([
        spieler.person.nachname, spieler.person.vorname, formatiereDatum(spieler.person.geburtsdatum),
        kat.bezeichnung, team.bezeichnung,
        formatiereBetrag(spieler.pflichtbeitrag, i18n.locale), spieler.anzahlRunden, 
        formatiereBetrag(spieler.betragPauschal, i18n.locale), formatiereBetrag(spieler.betragRunde, i18n.locale), formatiereBetrag(spieler.betragTotal, i18n.locale),
        formatiereBetrag(rechnung, i18n.locale), formatiereBetrag(ueberschuss, i18n.locale)
      ]);
    });
    
    downloadCsv(data, texte.csv.filenameSpieler);
  };

  return (
    <section>
      <div className="pull-right">
        <button onClick={() => spielerUpdate(null, 'alleBerechnen', true)} className="btn btn-small"><i className={`fa fa-refresh ${laufendeRequests[requestTyp.sponsorenlaufAlleBerechnen] ? 'fa-spin' : ''}`}/>&nbsp;{texte.alleBetraegeBerechnen}</button>
        &nbsp;
        {filterValues === null ||
          <>
            <Tooltip tagName="button" className="btn btn-small" content={texte.filter.loeschen} onClick={filterReset}>
              <i className="icon-remove"/>
            </Tooltip>
            &nbsp;
          </>
        }
        <button onClick={() => setShowFilter(!showFilter)} className="btn btn-small"><i className="icon-filter"/>{texte.filter.button}</button>
      </div>
      <h2 className="page-section">{texteGesamtuebersicht.titel}</h2>
      <div>
        <a href="" onClick={ev => generateCsv(ev)}>
          <IkonFuerDatei dateiname={'.csv'} />
        </a>
      </div>
      {showFilter && 
      <Filter filterValues={filterValues} teamIds={alleTeamIds} onFilterChange={filter => setFilterValues(filter)}/>
      }
      <Pager pageIndex={pageIndex} pageSize={pageSize} totalItemCount={gefilterteSpielerliste.length} pageSizeArray={pageSizeArray} onPageChange={pageIndexChange} onPageSizeChange={pageSizeChange}/>
      <table className="table attributes table-striped badge-compact">
        <thead>
        <tr>
          <th>{texteGesamtuebersicht.spieler}</th>
          <th>{texteGesamtuebersicht.team}</th>
          <th>{texte.anzahlRunden}</th>
          <th>{texteGesamtuebersicht.total}</th>
          <th>{texte.betragRechnung}</th>
          <th>{texteGesamtuebersicht.ueberschuss}</th>
        </tr>
        </thead>
        <tbody>
        {paginated.map((spieler) => {
          let team = teams.find(t => t.id === spieler.teamId);
          let ergebnis = spieler.pflichtbeitrag - spieler.betragTotal;
          return <tr key={spieler.id}>
            <td><PersonBadge person={spieler.person} /></td>
            <td>{team && <TeamBadge team={team}/>}</td>
            <td>{spieler.anzahlRunden}</td>
            <td className="text-right">{formatiereBetrag(spieler.betragTotal, i18n.locale)}</td>
            {ergebnis > 0 ?
            <>
              <td className="text-right fehlbetrag">{formatiereBetrag(ergebnis, i18n.locale)}</td>
              <td className="text-right">0</td>
            </> : 
              <>
                <td className="text-right">0</td>
                <td className="text-right ueberschuss">{formatiereBetrag(Math.abs(ergebnis), i18n.locale)}</td>
              </>
            }
          </tr>;
        })}
        </tbody>
      </table>
      <Pager pageIndex={pageIndex} pageSize={pageSize} totalItemCount={gefilterteSpielerliste.length} pageSizeArray={pageSizeArray} onPageChange={pageIndexChange} onPageSizeChange={pageSizeChange}/>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    spielerliste: state.spieler,
    teams: state.listen.teams,
    sponsoren: state.sponsoren,
    spielerkategorien: state.listen.spielerkategorien,
    pageSizeArray: state.listen.pager,
    laufendeRequests: state.laufendeRequests,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    spielerUpdate: (spieler, key, value) => { dispatch(spielerUpdate(spieler, key, value)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Gesamtuebersicht);
