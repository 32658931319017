import React, { useState } from "react";
import { connect } from "react-redux";
import ModalerDialog from "../../../components/ModalerDialog";
import { rechnungCreate } from "../state/modules/rechnungen";
import LeistungAuswahl from "./LeistungAuswahl";

const RechnungenHinzufuegen = ({ rechnungen, onHide, texte, rechnungCreate }) => {
  const [leistung, setLeistung] = useState(undefined);
  const [betragFreiwillig, setBetragFreiwillig] = useState(false);
  const [betrag, setBetrag] = useState('');
  const [error, setError] = useState(undefined);

  const speichern = () => {
    if (leistung) {
      setError(undefined);
      const leistungId = leistung.id;
      const betragswert = betrag;
      const personenIds = []
      rechnungen.forEach(rechnung => {
        personenIds.push(rechnung.person.id)
      });
      rechnungCreate(personenIds, leistungId, betragFreiwillig, betragswert);
      schliessen();
    } else {
      setError(texte.leistungModalError);
    }
  };

  const schliessen = () => {
    onHide();
  };

  const selectLeistung = (l) => {
    if (betrag === '') {
      setBetrag(l.betrag);
    }
    setLeistung(l);
  };

  return (
    <ModalerDialog>
      <div className="modal modal-wide">
        <div className="modal-header">
          <button type="button" className="close" onClick={schliessen}>&times;</button>
          <h3>{texte.rechnungenBearbeiten}</h3>
        </div>
        <div className="modal-body" style={{ overflow: 'visible' }}>
          {error &&
            <div className="alert alert-error">
              <span>{error}</span>
            </div>
          }
          <table className="table attributes ">
            <tbody>
            <tr>
              <th>{texte.leistung}</th>
              <td>
                <LeistungAuswahl value={leistung} onLeistungChange={(l) => selectLeistung(l)} istEntfernbar={true}/>
              </td>
            </tr>
            <tr>
              <th>{texte.betragFreiwillig}</th>
              <td>
                <input type="checkbox" checked={betragFreiwillig} onChange={ev => setBetragFreiwillig(ev.currentTarget.checked)}/>
              </td>
            </tr>
            <tr>
              <th>{texte.betrag}</th>
              <td>
                <input className="betrag" type="number" step="0.05" value={betrag} onChange={ev => setBetrag(ev.currentTarget.value)}/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary" onClick={() => speichern()}>{texte.speichern}</button>
          <button type="button" className="btn" onClick={schliessen}>{texte.abbrechen}</button>
        </div>
      </div>
    </ModalerDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    texte: state.i18n.texte.rechnungen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    rechnungCreate: (personenIds, leistungId, betragFreiwillig, betrag) => { dispatch(rechnungCreate(null, personenIds, leistungId, betragFreiwillig, betrag, '')); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RechnungenHinzufuegen);
