import React, {useState} from "react";
import ModalerDialog from "./ModalerDialog";

const ConfirmButton = ({children = undefined, tagName = "button", buttonText, confirmText, okText = 'OK', cancelText = 'Cancel', onConfirmed, className = "", ...props}) => {
  const [showModal, setShowModal] = useState(false);
  const DynamicTag = `${tagName}`;

  const confirmedClick = () => {
    setShowModal(false);
    onConfirmed();
  };

  return (
    <>
      <DynamicTag className={className} onClick={() => setShowModal(true)} {...props}>
        {children && <>{children}</>}
        {buttonText && <>{buttonText}</>}
      </DynamicTag>
      {showModal &&
      <ModalerDialog>
        <div className="modal" style={{top: '30%'}}>
          <div className="modal-body">{confirmText}</div>
          <div className="modal-footer">
            <button className="btn btn-primary" onClick={confirmedClick}>{okText}</button>
            <button className="btn" onClick={() => setShowModal(false)}>{cancelText}</button>
          </div>
        </div>
      </ModalerDialog>
      }
    </>
  );
};

export default ConfirmButton;
