export const BEXIO_STATUS = {
  nichtAufBexio: 'nicht_auf_bexio',
  draft: 'draft',
  pending: 'pending',
  paid: 'paid',
  partial: 'partial',
  unpaid: 'unpaid',
  canceled: 'canceled',
};

export const BEXIO_STATUS_BEARBEITBAR = [BEXIO_STATUS.draft, BEXIO_STATUS.nichtAufBexio, null];
export const BEXIO_STATUS_STORNIERBAR = [BEXIO_STATUS.draft, BEXIO_STATUS.pending];
export const BEXIO_STATUS_BEMERKUNGEN = [BEXIO_STATUS.pending, BEXIO_STATUS.partial, BEXIO_STATUS.canceled, BEXIO_STATUS.unpaid]

export const BEXIO_LINKS = {
  kontake: 'https://office.bexio.com/index.php/kontakt/list',
  konten: 'https://office.bexio.com/index.php/accountingSettings/accountList',
  rechnungen: 'https://office.bexio.com/index.php/kb_invoice/list',
  rechnungDetail: 'https://office.bexio.com/index.php/kb_invoice/show/id/'
};

export const RECHNUNGSLAUF_TYPEN = {
  rechnungslaufKonfiguration: 'rechnungslauf_konfiguration',
  rechnungenVerbaende: 'rechnungen_verbaende',
  sponsoren: 'sponsoren',
  vereinsmitglieder: 'vereinsmitglieder',
  sponsorenlauf_spieler: 'sponsorenlauf_spieler',
  sponsorenlauf_sponsor: 'sponsorenlauf_sponsor',
  spielerlizenzen: 'spielerlizenzen',
};

export const SPONSORENLAUF_EINSATZ = {
  pauschal: 10,
  runde: 20,
}
