import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { formatiereBetrag } from "../../../lib/formatierungen";
import { rechnungDelete, rechnungenStornieren } from "../state/modules/rechnungen";
import ConfirmButton from "../../../components/ConfirmButton";
import Pager from "../../../components/Pager";
import PersonBadge from "../../../components/PersonBadge";
import RechnungEdit from "./RechnungEdit";
import RechnungenFilter, {DEFAULT_FILTER} from "./RechnungenFilter";
import RechnungenEdit from "./RechnungenEdit";
import BexioStatusLabel from "./BexioStatusLabel";
import { BEXIO_LINKS, BEXIO_STATUS, BEXIO_STATUS_BEARBEITBAR, BEXIO_STATUS_STORNIERBAR } from "../lib/constants";
import Tooltip from "../../../components/Tooltip";
import { berechtigungFuer } from "../lib/berechtigungen";
import { bexioRechnungTransferStarten } from "../state/modules/bexio";
import SpielerSperrenModal from "./SpielerSperrenModal";
import { filtereRechnungen, sortiereRechnungen } from "../lib/datenHelper";
import CsvExportModal from "./CsvExportModal";
import RechnungBemerkungenEdit from "./RechnungBemerkungenEdit";
import Popover, { POSITION } from "../../../components/Popover";
import Prewrap from "../../../components/Prewrap";
import BexioResourceFehler from "./BexioResourceFehler";
import RechnungenBemerkungenModal from "./Rechnungslauf/RechnungenBemerkungModal";
import EmailSendenModal from "./Rechnungslauf/EmailSendenModal";
import RechnungenHinzuefuegen from "./RechnungenHinzuefuegen";

const Rechnungen = ({rechnungen, istVorschau, rechnungslauf, bexio, teams, darf, pageSizeArray, texte, locale, spielerStatistikUrl, rechnungDelete, rechnungenStornieren, bexioRechnungTransferStarten}) => {
  const [showModal, setShowModal] = useState(null); // generisch für viele modals, string mit name für gewähltes modal setzen zum anzeigen
  const [showRechnungBemerkungen, setShowRechnungBemerkungen] = useState(null);
  const [showSpielerSperren, setShowSpielerSperren] = useState(null);
  const [menuRechnungId, setMenuRechnungId] = useState(null);
  const [editRechnung, setEditRechnung] = useState(null);
  const [alleSelektieren, setAlleSelektieren] = useState(false);
  const [selectedRechnungenIds, setSelectedRechnungenIds] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [filterValues, setFilterValues] = useState(null);
  const [resetFilter, setResetFilter] = useState(0);
  const texteRechnungen = texte.rechnungen;
  const rechnungslaufTyp = rechnungslauf.typ;
  const bexioAbfrageAktiv = bexio.aktiv && (bexio.status.personen.aktiv || bexio.status.konten.aktiv || bexio.status.rechnungen.aktiv);

  const [pageSize, setPageSize] = useState(999999);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSort, setPageSort] = useState({name: 'person', desc: true});
  const startIndex = (pageIndex - 1) * pageSize;
  const endIndex = Math.min(pageIndex * pageSize, rechnungen.length);

  const aktiveBexioStati = useMemo(() => {
    const stati = [];
    if (bexio.aktiv) {
      // naiver ansatz: object hat nach forEach ein property pro bexio status
      const keys = {};
      rechnungen.forEach(r => keys[r.bexioStatus] = true);
      // alle BEXIO_STATUS beachten, wo es rechnungen dazu gibt
      for (let bs in BEXIO_STATUS) {
        let statusText = BEXIO_STATUS[bs];
        if (keys[statusText]) {
          stati.push({ key: statusText, text: texte.bexio.status[bs] });
        }
      }
    }
    return stati;
  }, [rechnungen]);

  const gefilterteRechnungen = useMemo(() => {
    return sortiereRechnungen(filtereRechnungen(rechnungen, filterValues, bexio.resourcenFehler), pageSort);
  }, [rechnungen, filterValues, bexio.resourcenFehler, pageSort]);

  const paginated = useMemo(() => {
    return gefilterteRechnungen.slice(startIndex, endIndex);
  }, [gefilterteRechnungen, startIndex, endIndex]);

  const stornierenEnabled = useMemo(() => {
    if (bexio.aktiv) {
      if (darf.rechnungStornieren) {
        const minDate = new Date(bexio.minimumDate);
        return selectedRechnungenIds.filter(rId => {
          const rechnung = rechnungen.find(r => r.id === rId);
          let erlaubt = BEXIO_STATUS_STORNIERBAR.includes(rechnung.bexioStatus);
          if (erlaubt && rechnung?.bexio?.freigabedatum) {
            erlaubt = new Date(rechnung.bexio.freigabedatum) > minDate;
          }
          return erlaubt;
        });
      }
      return [];
    } else {
      return [...selectedRechnungenIds];
    }
  }, [selectedRechnungenIds]);

  const bearbeitenEnabled = useMemo(() => {
    if (bexio.aktiv) {
      return selectedRechnungenIds.filter(rId => {
        const rechnung = rechnungen.find(r => r.id === rId);
        return BEXIO_STATUS_BEARBEITBAR.includes(rechnung.bexioStatus);
      });
    } else {
      return [...selectedRechnungenIds];
    }
  }, [selectedRechnungenIds]);

  const spielerSperrenEnabled = useMemo(() => {
    return selectedRechnungenIds.filter(rId => rechnungen.find(r => r.id === rId).istSpieler === false);
  }, [selectedRechnungenIds]);

  const kannBetragFreiwillig = berechtigungFuer(rechnungslaufTyp, 'betragFreiwillig');
  const kannTeamAnzeigen = berechtigungFuer(rechnungslaufTyp, 'team');
  const kannKommentar = berechtigungFuer(rechnungslaufTyp, 'kommentar');
  const kannNeueRechnung = berechtigungFuer(rechnungslaufTyp, 'neueRechnung');
  const kannSpielerSperren = berechtigungFuer(rechnungslaufTyp, 'spielerSperren');

  const sortieren = (feldname) => {
    if (pageSort && pageSort.name == feldname) {
      setPageSort({...pageSort, desc: !pageSort.desc});
    } else {
      setPageSort({name: feldname, desc: true});
    }
    pageIndexChange(1);
  };

  const filtern = filter => {
    setFilterValues(filter);
    pageIndexChange(1);
  };

  const filterReset = () => {
    setResetFilter(resetFilter + 1);
    setFilterValues(null);
    pageIndexChange(1);
  };

  const editClick = (rechnung) => {
    setMenuRechnungId(null);
    if (!istVorschau) {
      setEditRechnung(rechnung);
    }
  };

  const deleteClick = (rechnung) => {
    rechnungDelete(rechnung);
    pageIndexChange(1);
  };

  const rechnungBemerkungBearbeitenClick = (rechnung) => {
    setMenuRechnungId(null);
    setShowRechnungBemerkungen(rechnung);
  };

  const spielerSperrenClick = (rechnung) => {
    setMenuRechnungId(null);
    setShowSpielerSperren(rechnung);
  };

  const rechnungenStornierenClick = () => {
    rechnungenStornieren(selektierteRechnungen());
  };

  const pageIndexChange = (index) => {
    setMenuRechnungId(null);
    allesAuswaehlen(false);
    setPageIndex(index);
  };

  const pageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    pageIndexChange(1);
  };

  const allesAuswaehlen = (checked) => {
    setAlleSelektieren(checked);
    if (checked) {
      setSelectedRechnungenIds(paginated.map(r => r.id));
    } else {
      setSelectedRechnungenIds([]);
    }
  };

  const rechnungAuswaehlen = (rechnung, checked) => {
    if (checked) {
      setSelectedRechnungenIds([...selectedRechnungenIds, rechnung.id]);
    } else {
      setSelectedRechnungenIds(selectedRechnungenIds.filter(r => r !== rechnung.id));
    }
  };

  const selektierteLoeschen = () => {
    selectedRechnungenIds.forEach(id => {
      const rechnung = rechnungen.find(r => r.id === id);
      if (rechnung) {
        rechnungDelete(rechnung);
      }
    });
    pageIndexChange(1);
  };

  const transferInBexio = (rechnung) => {
    setMenuRechnungId(null);
    bexioRechnungTransferStarten(rechnung);
  };

  const menuClick = (rechnung) => {
    if (menuRechnungId === rechnung.id) {
      setMenuRechnungId(null);
    } else {
      setMenuRechnungId(rechnung.id);
    }
  };

  const renderItemMenu = (rechnung) => {
    if (bexioAbfrageAktiv) {
      return <></>;
    }

    const bearbeitbar = bexio.aktiv ? BEXIO_STATUS_BEARBEITBAR.includes(rechnung.bexioStatus) : true;
    const hatBexioReferenz = bexio.aktiv ? rechnung.bexioReferenzId > 0 : false;
    const istTransferiert = bexio.aktiv ? rechnungslauf.transferiert : false;
    const bemerkungBearbeitbar = bexio.aktiv && [BEXIO_STATUS.pending, BEXIO_STATUS.partial, BEXIO_STATUS.canceled, BEXIO_STATUS.unpaid].includes(rechnung.bexioStatus);

    return (
      <div className="open button-liste" style={{position: 'relative'}}>
        {bexio.aktiv &&
        <BexioStatusLabel rechnung={rechnung}/>
        }
        {(hatBexioReferenz || bearbeitbar) &&
        <>
          <button type="button" className="btn btn-mini" onClick={() => menuClick(rechnung)}>
            <i className="icon-cog"/>
          </button>
          {menuRechnungId === rechnung.id &&
          <ul className="dropdown-menu">
            {hatBexioReferenz &&
            <li>
              <a target="clubcorner-bexio-fenster" href={`${BEXIO_LINKS.rechnungDetail}${rechnung.bexioReferenzId}`} onClick={() => setMenuRechnungId(null)}>
                <i className="icon-circle-arrow-up"/> {texteRechnungen.rechnungInBexio}
              </a>
            </li>
            }
            {!hatBexioReferenz && istTransferiert &&
            <li>
              <a className="cursor-pointer" onClick={() => transferInBexio(rechnung)}>
                <i className="icon-hand-up"/> {texteRechnungen.transferInBexio}
              </a>
            </li>
            }
            {bearbeitbar &&
            <>
              {(hatBexioReferenz || !hatBexioReferenz && istTransferiert) &&
              <li className="divider"></li>
              }
              <li>
                <a className="cursor-pointer" onClick={() => editClick(rechnung)}>
                  <i className="icon-edit"/> {texteRechnungen.rechnungBearbeiten}
                </a>
              </li>
              <li>
                <ConfirmButton tagName="a" className="cursor-pointer" onConfirmed={() => deleteClick(rechnung)} confirmText={texteRechnungen.wirklichLoeschen} okText={texte.ok} cancelText={texte.abbrechen}>
                  <i className="icon-trash"/> {texteRechnungen.rechnungLoeschen}
                </ConfirmButton>
              </li>
            </>
            }
            {bemerkungBearbeitbar &&
            <>
              <li className="divider"></li>
              <li>
                <a className="cursor-pointer" onClick={() => rechnungBemerkungBearbeitenClick(rechnung)}>
                  <i className="icon-edit"/> {texteRechnungen.rechnungBemerkungBearbeiten}
                </a>
              </li>
            </>
            }
            {rechnung.istSpieler &&
              <>
                <li className="divider"></li>
                <li>
                  <a href={spielerStatistikUrl.replace('ersetzeSpielerId', rechnung.spielerInfo.id)}>
                    <i className="icon-list"/> {texteRechnungen.statistik}
                  </a>
                </li>
                {!istVorschau &&
                <li>
                  <a className="cursor-pointer" onClick={() => spielerSperrenClick(rechnung)}>
                    <i className="icon-ban-circle"/> {texteRechnungen.spielerSperren}
                  </a>
                </li>
                }
              </>
            }
          </ul>
          }
        </>
        }
        {rechnung.errorMessages &&
          <>
            <br/>
            <Popover title={texte.bexio.fehler} position={POSITION.top} content={<ul>{rechnung.errorMessages.map((error, index) => <li key={index}>{error}</li>)}</ul>}>
              <i className="icon-warning-sign"/>
            </Popover>
          </>
        }
      </div>
    );
  };

  const itemTemplate = (rechnung, index) => {
    const teamListe = teams.filter(t => rechnung.teamIds.includes(t.id));
    const spielerInfo = rechnung.spielerInfo;
    const contactFehler = bexio.aktiv ? bexio.resourcenFehler.find(rf => {
      switch (rf.referenceTyp) {
        case 'rechnung':
          return rf.referenceId === rechnung.id;
        case 'person':
          return rf.referenceId === rechnung.person.id;
        case 'spieler_sponsor':
          if (rechnung.sponsorenlaufInfo) {
            return rf.referenceId === rechnung.person.spielerSponsorId;
          }
          return false;
        default:
          return false;
      }
    }) : null;

    return (
      <tr key={index} className={rechnung.errorMessages ? 'error' : ''}>
        <td>
          <PersonBadge person={rechnung.person} personVerlinken={rechnung.istSpieler} showVcf={false} fotoTooltipAktiv={rechnung.istSpieler} style={{width: 180}}/>
          {rechnung.person.externerVerein &&
            <div>{`${rechnung.person.externerVerein}`}</div>
          }
          {rechnung.sponsorenlaufInfo &&
            <div>{`(${rechnung.sponsorenlaufInfo.personName})`}</div>
          }
          {spielerInfo &&
          <>
            {spielerInfo.qualifiziertAb &&
            <Tooltip content={texteRechnungen.qualifiziertAb} className="label label-important">{spielerInfo.qualifiziertAb}</Tooltip>
            }
            <div>
              <Tooltip content={texteRechnungen.eingangsdatum}>{spielerInfo.eingangsdatum}</Tooltip>
            </div>
          </>
          }
        </td>
        <td>
          {rechnung.adresse &&
          <>
            <div>{rechnung.adresse.strasse}</div>
            <div>{rechnung.adresse.plz} {rechnung.adresse.ort}</div>
          </>
          }
          {rechnung.adresse == null &&
          <span className="label label-warning">
            <i className="icon-warning-sign"/>
          </span>
          }
          {rechnung.personenInVerein &&
            <Popover className="icon-info-sign"
                     title={texteRechnungen.doppelteAdressenVerein}
                     content={<ul>{rechnung.personenInVerein.map(p => {
                       return <li key={p.id}>
                         <div>{p.nachname} {p.vorname}</div>
                         <div>{p.strasse}</div>
                         <div>{p.plz} {p.ort}</div>
                       </li>;
                     })}</ul>}
            />
          }
          {contactFehler &&
            <BexioResourceFehler resourceFehler={contactFehler}/>
          }
        </td>
        {kannTeamAnzeigen &&
        <td>
          {teamListe.map(team => <div key={team.id}>{team.bezeichnung}</div>)}
          <div>
            {rechnung.bemerkungen &&
            <Popover className="icon-info-sign" title={texteRechnungen.bemerkungen} content={<Prewrap>{rechnung.bemerkungen}</Prewrap>} />
            }
            {spielerInfo && spielerInfo.bemerkungen &&
            <Popover className="icon-info-sign" title={texteRechnungen.bemerkungenSpieler} content={<Prewrap>{rechnung.spielerInfo.bemerkungen}</Prewrap>}/>
            }
            {rechnung.bemerkungenMitglied &&
            <Popover className="icon-info-sign" title={texteRechnungen.bemerkungenMitglied} content={<Prewrap>{rechnung.bemerkungenMitglied}</Prewrap>}/>
            }
          </div>
          {spielerInfo && spielerInfo.gesperrt &&
          <Tooltip content={<Prewrap>{spielerInfo.gesperrtBemerkung}</Prewrap>} aktiv={!!spielerInfo.gesperrtBemerkung}>
            <span className="label label-important label-umbruch">{texteRechnungen.vereinsinternGesperrt}</span>
          </Tooltip>
          }
          {spielerInfo && spielerInfo.zumAbmeldenMarkiert &&
          <span className="label label-important label-umbruch">{texteRechnungen.zumAbmeldenMarkiert}</span>
          }
        </td>
        }
        {kannBetragFreiwillig &&
        <td>
          {rechnung.betragFreiwillig === true &&
          <i className="icon-ok"/>
          }
        </td>
        }
        <td>
          {rechnung.leistung.bezeichnung}
          {kannKommentar && rechnung.kommentar &&
            <>&nbsp;<Popover className="icon-info-sign" title={texteRechnungen.kommentar} content={<Prewrap>{rechnung.kommentar}</Prewrap>}/></>
          }
        </td>
        <td className="text-right">
          {formatiereBetrag(rechnung.betrag, locale)}
          <br/>
          {rechnung.konto}
          {rechnung.bexio && rechnung.bexio.restbetrag > 0 && rechnung.bexio.restbetrag < rechnung.betrag &&
          <>
            <br/>
            {formatiereBetrag(rechnung.bexio.restbetrag, locale)}
          </>
          }
        </td>
        {istVorschau ||
        <>
          <td>
            {renderItemMenu(rechnung)}
          </td>
          <td>
            <input type="checkbox" checked={selectedRechnungenIds.includes(rechnung.id)} onChange={ev => rechnungAuswaehlen(rechnung, ev.currentTarget.checked)}/>
          </td>
        </>
        }
      </tr>
    );
  };

  const renderSortierung = (feldname, text) => {
    const css = ['sort-me'];
    if (pageSort && pageSort.name === feldname) {
      css.push('active');
      if (pageSort.desc) {
        css.push('desc');
      } else {
        css.push('asc');
      }
    } else {
      css.push('desc');
    }
    return <div className={css.join(' ')} onClick={() => sortieren(feldname)}>{text}</div>;
  };

  const renderButtonListe = () => {
    if (istVorschau || bexioAbfrageAktiv) {
      return <></>;
    }

    let keineSelektion = selectedRechnungenIds.length === 0;

    return (
      <div className="button-liste">
        <div className="left">
          {kannNeueRechnung &&
            <button className="btn btn-primary" type="button" onClick={() => setShowModal('rechnungErstellen')}>{texteRechnungen.rechnungErstellen}</button>
          }
          <button className="btn btn-primary" disabled={keineSelektion} onClick={() => setShowModal('rechnungenHinzuefuegen')}>{texteRechnungen.rechnungenHinzuefuegen}</button>
          {bexio.aktiv ||
            <button className="btn btn-success" type="button" onClick={() => setShowModal('csvExport')} disabled={selectedRechnungenIds.length === 0}>{texteRechnungen.csvExport}</button>
          }
        </div>
        <div className="right">
          <button className="btn" disabled={keineSelektion} onClick={() => setShowModal('emailSenden')}>{texteRechnungen.emailSenden}</button>
          {bexio.aktiv && darf.rechnungBemerkungen &&
            <button className="btn" disabled={keineSelektion} onClick={() => setShowModal('rechnungenBemerkungen')}>{texteRechnungen.bemerkungenBearbeiten}</button>
          }
          <button className="btn" disabled={keineSelektion || bearbeitenEnabled.length < selectedRechnungenIds.length} onClick={() => setShowModal('rechnungenBearbeiten')}>{texteRechnungen.rechnungenBearbeiten}</button>
          {darf.spielerSperren && kannSpielerSperren &&
            <button className="btn btn-danger" disabled={keineSelektion || spielerSperrenEnabled.length > 0} onClick={() => setShowSpielerSperren(true)}>{texteRechnungen.spielerSperren}</button>
          }
          {bexio.aktiv && darf.rechnungStornieren &&
            <ConfirmButton className="btn btn-danger" disabled={keineSelektion || stornierenEnabled.length < selectedRechnungenIds.length} onConfirmed={rechnungenStornierenClick} confirmText={texteRechnungen.wirklichStornieren} okText={texte.ok} cancelText={texte.abbrechen}>{texteRechnungen.rechnungStornieren}</ConfirmButton>
          }
          <ConfirmButton className="btn btn-danger" disabled={keineSelektion || bearbeitenEnabled.length < selectedRechnungenIds.length} onConfirmed={selektierteLoeschen} confirmText={texteRechnungen.wirklichLoeschen} okText={texte.ok} cancelText={texte.abbrechen}>{texteRechnungen.selektierteLoeschen}</ConfirmButton>
        </div>
      </div>
    );
  };

  const renderTableFooter = () => {
    const colSpan = 3 + (kannTeamAnzeigen ? 1 : 0) + (kannBetragFreiwillig ? 1 : 0);
    const gesamttotalBetrag = gefilterteRechnungen.reduce((currentValue, rechnung) => currentValue + Number.parseFloat(rechnung.betrag), 0);
    const freiwilligerBetrag = gefilterteRechnungen.filter(r => r.betragFreiwillig).reduce((currentValue, rechnung) => currentValue + Number.parseFloat(rechnung.betrag), 0);
    const totalBetrag = gesamttotalBetrag - freiwilligerBetrag;

    return (
      <tfoot>
        <tr>
          <td colSpan={colSpan} className="text-right">
            {!isNaN(freiwilligerBetrag) && freiwilligerBetrag > 0 &&
            <>
              {isNaN(totalBetrag) ||
              <>
                <b>{texteRechnungen.totalBetrag}</b>
                <br/>
              </>
              }
              {isNaN(freiwilligerBetrag) ||
              <>
                <b>{texteRechnungen.totalFreiwillig}</b>
                <br/>
              </>
              }
            </>
            }
            {isNaN(gesamttotalBetrag) ||
            <b>{texteRechnungen.gesamttotalBetrag}</b>
            }
          </td>
          <td className="text-right">
            {!isNaN(freiwilligerBetrag) && freiwilligerBetrag > 0 &&
            <>
              {isNaN(totalBetrag) ||
              <>
                <b>{formatiereBetrag(totalBetrag, locale)}</b>
                <br/>
              </>
              }
              {isNaN(freiwilligerBetrag) ||
              <>
                <b>{formatiereBetrag(freiwilligerBetrag, locale)}</b>
                <br/>
              </>
              }
            </>
            }
            {isNaN(gesamttotalBetrag) ||
            <b>{formatiereBetrag(gesamttotalBetrag, locale)}</b>
            }
          </td>
          {istVorschau ||
          <td colSpan={2}></td>
          }
        </tr>
      </tfoot>
    );
  };

  const selektierteRechnungen = () => {
    return rechnungen.filter(r => selectedRechnungenIds.includes(r.id));
  };

  return (
    <>
      <h2 className="page-section">
        {`${texteRechnungen.titel}${istVorschau ? ` - ${texteRechnungen.vorschau}` : ''} (${gefilterteRechnungen.length})`}
        <span className="button-liste pull-right" style={{lineHeight: 1}}>
          {filterValues === null ||
          <Tooltip tagName="button" className="btn btn-small" content={texteRechnungen.filterLoeschen} onClick={filterReset}>
            <i className="icon-remove"/>
          </Tooltip>
          }
          <button type="button" className={`btn btn-small${filterValues === null || JSON.stringify(filterValues) === JSON.stringify(DEFAULT_FILTER) ? '' : ' btn-warning'}`} onClick={() => setShowFilter(!showFilter)}>
            <i className="icon-filter"/>
            {texteRechnungen.filter}
          </button>
        </span>
      </h2>
      <RechnungenFilter aktiveBexioStati={aktiveBexioStati} showFilter={showFilter} resetFilter={resetFilter} onFilterChange={filter => filtern(filter)}/>
      {renderButtonListe()}
      <Pager pageIndex={pageIndex} pageSize={pageSize} totalItemCount={gefilterteRechnungen.length} pageSizeArray={pageSizeArray} onPageChange={pageIndexChange} onPageSizeChange={pageSizeChange}/>
      <table className="table table-striped table-sort table-condensed">
        <thead>
          <tr>
            <th>
              {renderSortierung('person', texteRechnungen.person)}
              {renderSortierung('geburtsdatum', texteRechnungen.geburtsdatum)}
            </th>
            <th>
              {renderSortierung('strasse', texteRechnungen.strasse)}
              {renderSortierung('plz', texteRechnungen.plz)}
              {renderSortierung('ort', texteRechnungen.ort)}
            </th>
            {kannTeamAnzeigen &&
            <th>
              {texteRechnungen.team}
            </th>
            }
            {kannBetragFreiwillig &&
            <th className="text-nowrap">
              {renderSortierung('betragFreiwillig', <Tooltip content={texteRechnungen.betragFreiwillig}><i className="icon-heart"/></Tooltip>)}
            </th>
            }
            <th>
              {renderSortierung('leistung', texteRechnungen.leistung)}
            </th>
            <th className="text-right">
              {renderSortierung('betrag', texteRechnungen.betrag)}
              {renderSortierung('konto', texteRechnungen.konto)}
              {bexio.aktiv && renderSortierung('restbetrag', texteRechnungen.restbetrag)}
            </th>
            {istVorschau ||
            <>
              <th>
                {bexio.aktiv &&
                renderSortierung('bexio', 'bexio')
                }
              </th>
              <th>
                <input type="checkbox" disabled={bexioAbfrageAktiv} checked={alleSelektieren} onChange={() => allesAuswaehlen(!alleSelektieren)}/>
              </th>
            </>
            }
          </tr>
        </thead>
        <tbody>
        {paginated.map((item, index) => itemTemplate(item, index))}
        </tbody>
        {renderTableFooter()}
      </table>
      <Pager pageIndex={pageIndex} pageSize={pageSize} totalItemCount={gefilterteRechnungen.length} pageSizeArray={pageSizeArray} onPageChange={pageIndexChange} onPageSizeChange={pageSizeChange}/>
      {renderButtonListe()}
      {showModal === 'rechnungErstellen' &&
      <RechnungEdit onHide={() => setShowModal(null)}/>
      }
      {editRechnung &&
      <RechnungEdit rechnung={editRechnung} onHide={() => setEditRechnung(null)}/>
      }
      {showModal === 'rechnungenBearbeiten' &&
        <RechnungenEdit rechnungen={selektierteRechnungen()} onHide={() => setShowModal(null)}/>
      }
      {showModal === 'rechnungenHinzuefuegen' &&
        <RechnungenHinzuefuegen rechnungen={selektierteRechnungen()} onHide={() => setShowModal(null)}/>
      }
      {showRechnungBemerkungen &&
      <RechnungBemerkungenEdit rechnung={showRechnungBemerkungen} onHide={() => setShowRechnungBemerkungen(null)}/>
      }
      {showModal === 'emailSenden' &&
      <EmailSendenModal rechnungen={selektierteRechnungen()} onHide={() => setShowModal(null)}/>
      }
      {showModal === 'rechnungenBemerkungen' &&
      <RechnungenBemerkungenModal rechnungen={selektierteRechnungen()} onHide={() => setShowModal(null)}/>
      }
      {showSpielerSperren && // wenn true ist es die selektierte-liste, sonst ein einzelner spieler
      <SpielerSperrenModal rechnungen={showSpielerSperren === true ? selektierteRechnungen() : [showSpielerSperren]} onHide={() => setShowSpielerSperren(null)}/>
      }
      {showModal === 'csvExport' &&
      <CsvExportModal leistungenIds={selectedRechnungenIds} onHide={() => setShowModal(null)}/>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    rechnungslauf: state.rechnungslauf,
    bexio: state.bexio,
    teams: state.teams,
    darf: state.darf,
    pageSizeArray: state.typen.pager,
    texte: state.i18n.texte,
    locale: state.i18n.locale,
    spielerStatistikUrl: state.api.spielerStatistik,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    rechnungDelete: (rechnung) => { dispatch(rechnungDelete(rechnung)); },
    rechnungenStornieren: (rechnungen) => { dispatch(rechnungenStornieren(rechnungen)); },
    bexioRechnungTransferStarten: (rechnung) => { dispatch(bexioRechnungTransferStarten(rechnung)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rechnungen);
