import {executeJson} from "../../../../lib/fetchHelper";
import {sponsorenEdit} from "./sponsorenlaufSponsoren";
import {endeRequest, requestTyp, startRequest} from "./laufendeAbfragen";
import {listenFehler} from "./sponsorenlaufListen";

const SPONSORENLAUF_SPIELER_EDIT = 'SPONSORENLAUF_SPIELER_EDIT';
const SPONSORENLAUF_SPIELER_DELETE = 'SPONSORENLAUF_SPIELER_DELETE';

export function spielerSortieren(a, b) {
  return a.person.name.localeCompare(b.person.name);
}

export function spielerEdit(spieler) {
  return (dispatch) => {
    dispatch({type: SPONSORENLAUF_SPIELER_EDIT, spieler: [spieler]});
  };
}
export function spielerCreate(spielerkategorieId, teamId, spieler) {
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;
    const data = {
      sponsorenlaufId: state.sponsorenlauf.id,
      spielerkategorieId: spielerkategorieId,
      teamId: teamId,
      spieler: spieler
    };
    executeJson(api.spieler, api.token, 'POST', data)
      .then(result => {
        if (result.ok) {
          dispatch({type: SPONSORENLAUF_SPIELER_EDIT, spieler: result.data.spieler});
        }
      });
  }
}

export function spielerUpdate(sponsorlaufSpielerId, key, value) {
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;
    const data = {
      sponsorlaufSpielerId: sponsorlaufSpielerId,
    };
    data[key] = value;
    dispatch(startRequest(requestTyp.sponsorenlaufAlleBerechnen));
    executeJson(`${api.spieler}/${state.sponsorenlauf.id}`, api.token, 'PUT', data)
      .then(result => {
        dispatch({type: SPONSORENLAUF_SPIELER_EDIT, spieler: result.data.spieler});
        if (result.data.sponsoren) {
          dispatch(sponsorenEdit(result.data.sponsoren));
        }
      })
      .finally(() => {
        dispatch(endeRequest(requestTyp.sponsorenlaufAlleBerechnen));
      });
  };
}

export function spielerDelete(sponsorlaufSpielerId) {
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;
    const data = {
      sponsorlaufSpielerId: sponsorlaufSpielerId,
    };
    executeJson(`${api.spieler}/${state.sponsorenlauf.id}`, api.token, 'DELETE', data)
      .then(result => {
        if (result.ok) {
          dispatch({type: SPONSORENLAUF_SPIELER_DELETE, sponsorlaufSpielerId: sponsorlaufSpielerId});
        } else {
          if (result.data.fehler) {
            dispatch(listenFehler(result.data.fehler));
          }
        }
      });
  };
}

function sponsorenlaufSpielerReducer(state = [], action) {
  switch (action.type) {
    case SPONSORENLAUF_SPIELER_EDIT:
      let neueSpielerIds = action.spieler.map(s => s.id);
      let liste = state.filter(s => neueSpielerIds.includes(s.id) === false).concat(action.spieler);
      return liste.sort(spielerSortieren);
    case SPONSORENLAUF_SPIELER_DELETE:
      return state.filter(s => action.sponsorlaufSpielerId.includes(s.id) === false);
    default:
      return state;
  }
}

export default sponsorenlaufSpielerReducer;
